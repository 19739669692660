$pad: 15px;
$mar: 15px;
$blue: #304e8c;
$grey: #707070;
$greylight: #dfdfdf;
$green: #666;
$lightblue: #4e6eb1;
$bluergb: rgb(48,78,139);


$font:'Open Sans', sans-serif;

.wow{ opacity: 0; }

.hidden {
  display: none;
}
.pad { padding: $pad; }
.pad-left { padding-left: $pad; }
.pad-right { padding-right: $pad; }
.pad-top { padding-top: $pad; }
.pad-bot { padding-bottom: $pad; }

.small-pad { padding: $pad/2; }
.small-pad-left { padding-left: $pad/2; }
.small-pad-right { padding-right: $pad/2; }
.small-pad-top { padding-top:$pad/2; }
.small-pad-bot { padding-bottom: $pad/2; }

.mar { margin: $mar; }
.mar-left { margin-left: $mar; }
.mar-right { margin-right: $mar; }
.mar-top { margin-top: $mar; }
.mar-bot { margin-bottom: $mar; }

/* TO IMPORT TO LAYOUT.CSS*/
.small-mar-right { margin-right: $mar/2;  }
.small-mar-left {  margin-left: $mar/2;  }

.email2 {
  display: none;
}

/* Text */
body {}
h1  {
  text-transform: uppercase;
  margin:0px 0px $mar 0;
  padding: 0px;
  display: block;
}
h2 {
  text-transform: uppercase;
  margin:0px 0px $mar 0;
  padding: 0px;
  display: block;
  color: $green;
}
h3 {}
h4 {}
p {}

/* Buttons */

.button {
  padding: 5px 30px;
  border: 0;
  color: #FFFFFF;
  text-transform: uppercase;
  font-family: $font;
  background-color: $blue;
}
.button:hover {
  background-color: $lightblue;
  color: #FFFFFF;
  text-decoration: none;
}
.button-inverse {
  padding: 5px 30px;
  color: #000 !important;
  background-color: $greylight;
  text-transform: uppercase;
  font-family: $font;
  transition: all 1s;
  border: 1px solid $blue;
}
.button-inverse:hover {
  background-color: #FFFFFF;
  color: #000;
  text-decoration: none;
  transition: all 1s;
  cursor: pointer;

}
.btn-primary {
  background-color: $bluergb;
  border:1px solid $bluergb;
}
a.btn-primary {
  color: #FFF !important;
}
/* Header */
.header-text {
  padding-top: 123px;
}
header {
  background-color: #FFF;
  position: fixed;
  top:0px;
  width:100%;
  z-index: 999;
  border-bottom: 2px solid $blue;

  .navbar {
    height:80px;
  }

  .navbar-nav .nav-item+.nav-item {
    margin-left: 0;
  }
  .logo {
    height:80px;
    width:auto;
  }

  .nav-link {
    text-transform: uppercase;
    color: $blue !important;
    padding:15px;
    line-height: 66px;
    transition: all 0.5s ease;
    display: block;
  }
  .nav-link:hover {
    color: $grey !important;
  }
  .nav-icon {
    color: #FFF !important;
    padding:5px 5px;
    line-height: 66px;
    transition: all 0.5s ease;
    display: block;
    font-size: 24px;
  }
  .nav-icon:hover .fa-circle {
    color: $grey !important;
  }
  .nav-icon .fa-circle {
    color: $blue;
  }

}


.row {
  margin-right: 0 !important;
  margin-left: 0 !important;

  & > [class^="col-"],
  & > [class*=" col-"] {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
.blog-search {
  margin-right: 0 !important;
  margin-left: 0 !important;

  & > [class^="col-"],
  & > [class*=" col-"] {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.page-contents {
  min-height:10em;
}
.page-padded {
  padding-top:98px;
}

/* Header Images */
.image-header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  height:380px;
  text-align: center;
  margin-top: 98px;
  position: relative;
  color: #FFF;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
  border-bottom:4px solid $green;
  h1 {
    display: block;
    color: #FFF;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
  }
  .whitebox {
    color: #000 !important;
    text-shadow: none !important;
  }
}
/* FOOTER */
.footer-one {
  background-color: #FFFFFF;
  padding: $pad*2;
  border-top:2px solid $lightblue;
  border-bottom:2px solid $lightblue;
}
.footer-one .newsletter-wrap {
   width: 40%;
   margin: 0 auto;
}
.footer-one .newsletter-title {
  color: #000000;
  text-align: center;
  margin-bottom: $mar;
}
.footer-one input {
  border: 3px solid $blue;
  border-radius: 0;
}
.footer-one .newsletter-button {
  border: 3px solid $blue;
  background-color: $blue;
  color: #FFFFFF;
  border-radius: 0;
  text-transform: uppercase;
}
.footer-two {
  background-color: #555555;
  text-align: center;
}
.footer-two .social-media {
  padding:0px;
  list-style: none;
  width:auto;
  margin:0 auto;
  display: inline-block;
}
.footer-two .social-media li {
  display: inline;
  padding:5px;
  margin:0px 5px;
  float: left;
  width:auto;
}
.footer-two .social-media li a {
  color: #FFF;
  width:2em;
  transition: all 0.5s ease;
}
.footer-two .social-media li a:hover {
  color: #000;
}
.footer-two .footer-menu {
  margin:0 auto;
  padding:0px;
  list-style: none;
  text-align: center;
  width:auto;
  display: inline-block;
}
.footer-two .footer-menu li {
  width: auto;
  display: inline;
  float: left;
}
.footer-two .footer-menu li a {
  display: block;
  padding:5px;
  color: #FFF;
  float: left;
  transition: all 0.5s ease;
  margin: $mar 5px;
  text-transform: uppercase;
}
.footer-two .footer-menu li a:hover {
  color: #000;
  text-decoration: none;
}
/* Side Menu */
ul.side-menu {
  background-color: $lightblue;
  list-style: none;
  margin:0px 0px $mar 0px ;
  padding:0px;

    li {
      border-bottom: 1px solid #FFF;
      transition: all 0.5s ease;

      a {
        display: block;
        padding: $pad;
        color: #FFF;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
    li:hover {
      background-color: $blue;
    }
}

/* Hexagons */
.account-user{
  font-size: 45px;
  padding: 10px;
  position: absolute;
  top:-6px;
  left:24px;
}
.account-hexagon-link {
  color: #000000;
}
.account-hexagon-link:hover {
  color: #FFFFFF;
}
.account-hexagon {
  width: 100px;
  height: 55px;
  background: #cccccc;
  position: relative;
  text-align: center;
  transition: all 1s;
  margin:35px auto;
  display: block;
}
.account-hexagon:before {
  content: "";
  position: absolute;
  top: -25px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 25px solid #cccccc;
  transition: all 1s;
}
.account-hexagon:after {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 25px solid #cccccc;
  transition: all 1s;
}
.account-hexagon-link:hover .account-hexagon {
  background-color: $blue;
}
.account-hexagon-link:hover .account-hexagon:before {
  border-bottom: 25px solid $blue;
}
.account-hexagon-link:hover .account-hexagon:after {
  border-top: 25px solid $blue;
}

/* Alerts */
.alert ul {
  margin:0px;
  padding:0px;
  list-style: none;
}

/* Logins */
.login-loading {
  padding:30px;
  text-align: center;
  display: none;
}
.login-success {
  text-align: center;
  display: none;
}
/* Widgets */
/* WIDGET EDIT */
.widget-wrap {
  position: relative;
}
.widget-edit {
  display: none;
}
.widget-admin:hover  {
  display: block;
  border: 1px solid #000;
  min-height:55px;
}
.widget-admin:hover .widget-edit {
  display: inline-block;
  position: absolute;
  right:0px;
  padding: 10px;
  background-color: rgba(0,0,0,0.8);
}
.widget-admin:hover a {
  color: #FFF;
}

/* Image Captions */
.image-caption {
  font-size: 1em;
  text-align: center;
  color: $grey;
  font-style: italic;
}

/* Boxes */
.grey {
  background-color: $greylight;
}

/* Slick Generic */

.slick-arrow {
  position: absolute;
  top: 40%;
  border: 5px solid #FFFFFF;
  padding: 10px;
  color: #FFFFFF;
  z-index: 990;
  font-size: 28px;
}
ul.slick-slider {
  margin:0px;
  padding:0px;
  list-style: none;
}

/* Home Page */
.home-blog-new {
  height: 450px;
  overflow: hidden;
  border-bottom:4px solid $green;
}
#home-header-social {
  a{
    margin-right:25px;
    color: #FFF;
    box-shadow: none;
  }
  .fa-circle {
    color: $lightblue !important;
    font-size: 62px;
    box-shadow: none;
    text-shadow: none;
  }
  .fa-stack-1x {
    text-align: center;
    color: #FFF;
    font-size: 30px;
    margin:0 auto;
    top:13px;
    left:10px;

  }
}
#home-horses {
  .bx-viewport {
    box-shadow: none;
  }
  .bx-next {
    top:35%;
    right:-50px;
  }
  .bx-prev {
    top:35%;
    left:-50px;
  }
  .horse-image-wrap {
    height:220px;
    width:220px;
    border-radius: 100%;
    overflow: hidden;
    margin:0 auto;

      img {
        min-height: 100%;
        min-width: 100%;
        width:auto;
      }
  }
  .horse-name-wrap {
    height:80px;
    text-align: center;
    margin-top:$mar;

      a {
        font-size: 1.5em;
        color: $lightblue;
      }
  }
}

.home-news {
  background-color: #FFF;
  padding:$pad;
  margin-bottom: $mar;

    .news-image {
      height:180px;
      width:100%;
      overflow: hidden;

      img {
        min-height: 180px;
        max-height: 180px;
        min-width: 100%;

      }
    }
}

.whitebox {
  padding:10px;
  background-color: #FFF;

  h3 {
    margin-bottom:$mar;
    text-align: center;
  }
  .home-sales-text {
    margin: $mar auto;
  }
}


/* Blog */
.blog-card-box {
  .btn-primary {
    background-color: red;
    border: none;
  }
  .btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    background-color: #000;
  }
}
.blog-card-box {
  padding: 0 0 0px 0;
  text-align: center;
  margin: 7.5px 0 $mar 7.5px;
}
.blog-card-box a:hover, .blog-card-box:hover {
  text-decoration: none;
}
.blog-card-title {
  min-height: 48px;
}
.blog-card-box a {
  color: #FFFFFF;
}
.blog-card-box p {
  margin-bottom: 0;
}
.blog-card-image {
  height: 250px;
  overflow: hidden;
  position: relative;
}
.blog-card-image img {
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  bottom:0;
  height: 250px;
  width:auto;
}
.blog-card-text {
  margin-top: 10px;
  min-height: 90px;
  font-size: 13px;
}
.blog-card-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 182px;
  text-align: center;
  padding: 10px;
  background-color: $bluergb;
  color: #FFFFFF !important;
  text-transform: uppercase;
  font-weight: bold;
  transition: all 1s;
  height: 250px;
}
.blog-card-box:hover .blog-card-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 80px;
  display: block;
  background-color: rgba($bluergb,0.7);
}

/* Blog View Page */

.article-gallery {
  .slick-slide img, .slick-slide iframe {
    margin:0 auto;
  }
  .slide-left {
    left:-37px;
    background-color: #FFF;
  }
  .slide-right {
    right:-37px;
    background-color: #FFF;
  }
  .slick-arrow {
    border: 5px solid $green;
    color: $green;
    top:45%;
  }
  .slick-arrow:hover {
    cursor: pointer;
    background-color: $greylight;
  }
}
.small-gallery {
  .slick-arrow {
    border: 5px solid $green;
    color: $green;
    top:40%;
  }
  .slide-left {
    left:-10px;
    background-color: #FFF;
  }
  .slide-right {
    right:-10px;
    background-color: #FFF;
  }
  .slick-list {
    overflow: hidden;
    width:100%;
    max-height:300px;
  }
}
.index-box {
  margin: $mar $mar 0 0;
  position: relative;
  min-height: 200px;
  overflow: hidden;
}
.index-box-text {
  text-align: center;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.index-box .index-link {
  padding: $pad;
  background-color: rgba(0,0,0,1);
  text-align: center;
  min-height: 200px;
  width: 100%;
  position: absolute;
  right: -347.5px;
  color: #FFFFFF;
  transition:  all 1s;
  font-size: 32px;
}
.fa.pull-left {
  margin-right: 15px;
}
.index-box:hover .index-link {
  background-color: rgba(0,0,0,0.7);
  right: 0;
  transition:  all 1s;
}
.index-box .index-link .fa-angle-double-right {
  display: none;
  transition: all 1s;
}
.index-box .index-link:hover .fa-angle-double-right {
  display: block;
  transition: all 1s;
}
.index-box .index-link .fa-angle-double-left {
  display: block;
  transition: all 1s;
}
.index-box .index-link:hover .fa-angle-double-left {
  display: none;
  transition: all 1s;
}
.index-box .index-link .index-link-icon {
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
  margin-top: 64px;
  text-transform: uppercase;
  text-align: center;
}

.index-right-box {
  background-color: #dfdfdf;
  padding: $pad;
  margin-top: $mar;
  text-align: center;
  min-height: 415px;
}
.index-right-box h2 {
  text-align: center;
  margin: $mar 0;
}
.index-about-button {
  margin-bottom: 3.8px;
}
.portrait {
  border: 1px solid #000000;
  border-radius: 50%;
  overflow: hidden;
  width: 180px;
  height: 180px;
  margin: 0 auto;
}
.portrait img {
  min-height: 100%;
  min-width: 100%;
  height: 180px;
  width: auto;
  max-width: none;
  position: relative;
  left: -50px;
}
.index-right-box-2 {
  background-color: #DFDFDF;
  padding: $pad;
  margin-top: $mar;
  text-align: center;
}
.index-banner {
  background-color: #707070;
  margin-top: $mar;
  padding: $pad;
  color: #FFFFFF;
  font-size: 32px;
  min-height: 143.2px;
  text-transform: uppercase;
  line-height: 113.2px;
  vertical-align: middle;
}
.index-banner-2 {
  min-height: 160px !important;
  line-height: 130px;
  vertical-align: middle;
}
.index-banner p, .index-banner a {
  margin-bottom: 0;
  color: #FFFFFF;
}
.search-wrap input, .search-wrap input:focus, .search-wrap input:active {
  border-radius: 0;
  background-color: $green;
  color: #FFFFFF;
  border: 0;
  height: 36px;
  line-height: normal;
}
.search-icon-wrap {
  height: 36px;
  padding: 5px;
  text-align: center;
  background-color: #FFFFFF;
  border-left: 1px solid #000000;
  width: 100%;
  min-width: 41px;
}
.search-icon-wrap:hover {
  cursor: pointer;
}
.top-search-button {
  background-color: rgba(0,0,0,0);
  border: 0;
}
.top-search-button:hover {
  cursor: pointer;
}
::-webkit-input-placeholder {
  color: #FFFFFF !important;
}
:-moz-placeholder { /* Firefox 18- */
  color: #FFFFFF !important;
}
::-moz-placeholder {  /* Firefox 19+ */
  color: #FFFFFF !important;
}
:-ms-input-placeholder {
  color: #FFFFFF !important;
}
.featured-blog-wrap {
  margin-top: $mar;
  min-height: 355.4px;
}
.featured-blog-text {
  padding: $pad;
  text-align: center;
  background-color: #dfdfdf;
}
.featured-blog-button {
  padding: 5px 30px;
  color: #FFFFFF;
  background-color: #000000;
  text-transform: uppercase;
  font-family: $font;
  margin-bottom: 3px;
  transition: all 1s;
}
.featured-blog-button:hover {
  text-decoration: none;
  background-color: #FFFFFF;
  color: $green;
  transition: all 1s;
}

/* Video Bits */
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}



.temp-hidden {
  display: none;
}

/* news Comments */
.comment-wrap {
  margin: $mar 0;
  padding: $pad;
  background-color: #dfdfdf;
  border-radius: $pad;

}
.user-comments {
  font-size: 14px;
  font-style: italic;
}

.form-group label {
  margin-bottom: 0 !important;
}

/* Contact Social */
.contact-social {


  ul {
    list-style: none;
    margin:0px;
    padding:0px;
    border-bottom: 1px solid #dedede;


    li {
      display: block;
      min-height:40px;
      line-height: 30px;
      vertical-align: middle;
      border-top: 1px solid #dedede;
      padding:$pad 0;


      a {
        color: $grey;
        display: block;
        i {
          padding-right: 10px;
          width: 40px;
          height: 40px;
          display: block;
          float: left;
        }
      }
      a:hover {
        text-decoration: none;
        color: $blue;

        i {
          color: $blue;
        }
      }
    }

  }
}

ul.pagination {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 22px;
}

ul.pagination li {
  display: inline;
  float: left;
  border-radius: 5px;

}
ul.pagination li span {
  margin: 0 4px; /* 0 is for top and bottom. Feel free to change it */
  padding: 8px 16px;
  display: inline-block;
}
ul.pagination li.active span {
  margin: 0 4px; /* 0 is for top and bottom. Feel free to change it */
  padding: 8px 16px;
  display: inline-block;
  background-color: #dedede ;
}

ul.pagination li a {
  color: black;
  display: inline-block;
  text-decoration: none;
  margin: 0 4px; /* 0 is for top and bottom. Feel free to change it */
  padding: 8px 16px;
}
ul.pagination li a.active {
  background-color: $greylight;
  color: white;
}

ul.pagination li a:hover:not(.active) {
  background-color: $green;
  color: white;
}


ul.pagination li a.active {
  border-radius: 5px;
}
/* ABOUT PAGE */
.about-image {
  border-radius: 50%;
  width: 180px;
  height: 180px;
  margin: 0px auto;
  background-size: auto 100%;
  background-position: center;
}
/* HORSE SECTION */
.horses-news-wrap .col-md-2, .horses-news-wrap .col-md-10 {
  padding: 0;
}
.horses-news-wrap {
  padding: $pad;
  margin: 0 0 $mar*2 0;
  color: #000000;
  background-color: #FFFFFF;

}
.horses-news-wrap h2 {
  color: #000000;
  font-size: 1.75rem;
}
.horse-strapline {
  font-weight: bold;
}
.gray-background {
  background-color: #e2e2e2;
  padding: $pad;
}
.horses-gallery-button {
  text-align: center;
  margin: 35px 0 35px 0;
}
.horses-page {
  margin-top: $mar*2;
}
.horses-page h2 {
  color: #000000;
  font-size: 1.75rem;
}
#html5-text, #html5-elem-data-box {
  display: none !important;
}
.article-strapline {
  font-size: 2rem;
}
/* Ribbons */
.ribbon{
  position:absolute;
  padding:0;
  margin:0;
  text-align:center;
  white-space:nowrap;
  overflow:hidden;
  transform-origin:0 100%;
  box-shadow:0 1px 6px 0 rgba(0,0,0,.12),0 1px 6px 0 rgba(0,0,0,.12);
  height:30px;
  width:200px;
  top:-30px;
  transform:rotateZ(45deg);
  padding-left:30px;
  padding-right:30px;
  line-height:30px;
  right:-58.57864376269px;
  -webkit-transform:rotateZ(45deg);
  -moz-transform:rotateZ(45deg);
  -o-transform:rotateZ(45deg);
  color: #FFF;
  z-index: 99;
}
.ribbon-red {
  background-color: #e00404;
}
.ribbon-green {
  background-color: #0ca005;
}

.ribbon-blue {
  background-color: $lightblue;
}
/* Courses */
.courses-dates-wrap {
  padding: $pad;
  margin: 0 0 $mar*2 0;
  color: #000000;
  background-color: #FFFFFF;
}
.courses-dates-wrap p {
  margin-bottom: 0;
}
.courses-dates-wrap h2 {
  color: #000000;
  font-size: 1.75rem;
}
/* Calender  */
time.icon
{
  font-size: 1em; /* change icon size */
  display: block;
  position: relative;
  width: 7em;
  height: 7em;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 0.6em;
  box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 4px 0 #fff, 0 5px 0 #bdbdbd, 0 0 0 1px #bdbdbd;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: rotate(0deg) skewY(0deg);
  -webkit-transform-origin: 50% 10%;
  transform-origin: 50% 10%;
}

time.icon *
{
  display: block;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  text-align: center;
}

time.icon strong
{
  position: absolute;
  top: 0;
  padding: 0.4em 0;
  color: #fff;
  background-color: $blue;
  border-bottom: 1px dashed $blue;
  box-shadow: 0 2px 0 $blue;
}

time.icon em
{
  position: absolute;
  bottom: 0.3em;
  color: $blue;
}

time.icon span
{
  width: 100%;
  font-size: 2.8em;
  letter-spacing: -0.05em;
  padding-top: 0.8em;
  color: #2f2f2f;
}

time.icon:hover, time.icon:focus
{
  -webkit-animation: swing 0.6s ease-out;
  animation: swing 0.6s ease-out;
}

@-webkit-keyframes swing {
  0%   { -webkit-transform: rotate(0deg)  skewY(0deg); }
  20%  { -webkit-transform: rotate(12deg) skewY(4deg); }
  60%  { -webkit-transform: rotate(-9deg) skewY(-3deg); }
  80%  { -webkit-transform: rotate(6deg)  skewY(-2deg); }
  100% { -webkit-transform: rotate(0deg)  skewY(0deg); }
}

@keyframes swing {
  0%   { transform: rotate(0deg)  skewY(0deg); }
  20%  { transform: rotate(12deg) skewY(4deg); }
  60%  { transform: rotate(-9deg) skewY(-3deg); }
  80%  { transform: rotate(6deg)  skewY(-2deg); }
  100% { transform: rotate(0deg)  skewY(0deg); }
}


.cal-course-wrap h3 a{
  color: $blue !important;
}
.contents-offset-top {
  position: relative;
  top:-40px;
  z-index:999;
}

/* Shop */

/* Products */
.products-category-wrap {
  margin:10px;
  padding:10px;
  transition: all .2s ease-in-out;
  border: 1px solid $lightblue;
  background-color: #ececec;

    .image-wrap {
      height:150px;
      background-color: #FFF;
      margin-bottom:15px;
      overflow: hidden;
    }
    .image-wrap img {
      transition: all .2s ease-in-out;
    }
    .category-wrap:hover .category-image-wrap img {
      transform: scale(1.1);
    }

    .text-wrap {
      height:60px;
      text-align: center;
      font-size: 18px;
    }
}
.products-wrap {
  border: 1px solid #dedede;
  margin:10px;
  padding:10px;
  position: relative;
}
.products-wrap:hover img, .home-products-wrap:hover img {
  transform: scale(1.1);
}
.products-image-wrap {
  height:150px;
  background-color: #FFF;
  margin-bottom:15px;
  overflow: hidden;
}
.products-image-wrap img, .home-products-wrap img {
  transition: all .2s ease-in-out;
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin:0 auto;
  width:auto;
  height:auto;
}
.products-wrap:hover .products-image-wrap img {
  transform: scale(1.1);
}
.products-text-wrap {
  min-height:75px;
  text-align: center;
  font-size: 16px;
}
.products-text-wrap a {
  color: #000;

}
.products-text-wrap a:hover {
  color: #000;
  text-decoration: underline;
}
.products-wrap:hover a {
  color: #000;
  text-decoration: underline;
}
.products-price-wrap {
  text-align: center;
  color: #333;
  font-size: 14px;
}

/* Products Options */

.products-option-wrap {
    .btn {
      padding:5px;
    }
    input {
      line-height: 1.9em;

    }
}


/* Side Menu */
.cat-header h1 {
  padding: 15px 0px;
  border-bottom: 1px solid #666;
  margin-bottom:15px;
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
  margin:0px;
}
.side-menu h4{
  padding: 15px 0px;
  border-bottom: 1px solid #666;
  margin-bottom:15px;
  text-align: left;
  font-size: 24px;
  text-transform: uppercase;
  margin:0px;
}
.shopmenu ul li > ul {
  display: none;
}
.shopmenu ul li > ul li a {
  color: #000;
}
.shopmenu ul {
  margin:0px;
  padding:0px;
  list-style: none;
}
.shopmenu ul li {
  border-bottom:1px solid #dedede;
}
.shopmenu ul li a {
  display: block;
  padding:8px;
  background-color: #FFF;
}
.shopmenu ul li a:hover {
  padding-left: 20px;
}
.products-price-wrap {
  color: #dd052e;
  font-size: 24px;
}
.products-price-wrap small {
  font-size: 14px;
}
/* Products View Page */
.products-slider-wrapper {
  border: none;
  padding: 10px;
}
.products-slider-wrapper .bx-wrapper .bx-viewport {
  border:0px;
  box-shadow: none !important;
  left: 0px !important;
  border:1px solid #CCC;
}
.products-slider-wrapper .bx-wrapper .bx-next {
  right:-15px;
}
.products-slider-wrapper .bx-wrapper .bx-prev {
  left:-15px;
}
.products-option-wrap {
  padding: 10px 0px;
  border-bottom:1px solid #f7f7f7;
}
.products-option-wrap small {
  color: #ccc;
  font-size: 10px;
}
.products-options-wrap .center{
  width: 150px;
  margin: 40px auto;
}
.input-number {
  text-align: center;
  padding:0px !important;
}
.product-option-price {
  font-size: 18px;
  min-height: 40px;
  line-height: 40px;
  vertical-align: middle;
}
.product-alert {
  background-color: #dd052e;
  border-radius: 10px;
  color: #FFFFFF;
  padding: 10px 10px;
  margin: 0px -10px 10px -10px;
}
.product-alert-text {
  line-height: 42px;
  vertical-align: middle;
}
.instock {
  min-height: 40px;
  line-height: 40px;
  vertical-align: middle;
}
.outstock {
  min-height: 40px;
  line-height: 40px;
  vertical-align: middle;
}
.shop-button {
  display: block;
  color: #FFFFFF !important;
  font-size: 28px;
}
.modal-header h4 {
  color: #FFFFFF;
  text-align: center;
  font-weight: bold;
}
.modal-body {
  padding: 10px 20px;
}
/* TABS ON PRODUCT VIEW PAGE */
.products-tabs .nav-tabs {
  margin-bottom: -2px;
}
.products-tabs .tab-content {
  border: solid 2px #dd052e;
  padding: 10px 20px;
}
.products-tabs .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a {
  color: #FFFFFF;
  background-color: #dd052e;
  border: 2px solid #dd052e;
  border-bottom-color: transparent;
  border-radius: 0px;
}
.products-tabs .nav-tabs li a {
  color: #333;
  margin-right: 0px;
  border: 2px solid #dd052e;
  border-radius: 0px;
}
.products-tabs .nav-tabs .active a:hover {
  background-color: #dd052e !important;
  color: #FFFFFF;
}
.products-tabs .nav-tabs li a:hover {
  color: #dd052e;
  background-color: #FFFFFF;
}
#description h4 {
  font-weight: bold;
}
/* BASKET PAGE */
.basket-empty-icon {
  font-size: 164px;
}
.basket-empty-text {
  font-size: 42px;
  margin-bottom: 0px;
  line-height: 164px;
  vertical-align: middle;
}

/* Basket Number Circle */
.number-circle {
  background-color: #FF0000;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  color: #FFF;
  font-weight: bold;
  border: 3px solid #FFF;
  position: absolute;
  right: 10px;
  top: 22px;
  font-size: 8px;
  line-height: 18px;
}

/* User Account Pages */
.user-level-box {
  background-color: $lightblue;
  padding:10px;
  color: #FFF;
  margin-bottom:$mar;

  h2 {
    color: #FFF;
    font-size: 20px;
    padding-bottom: $pad;
    border-bottom: 1px solid #FFF;
    margin-bottom: $mar;
    text-align: center;
  }
  .level {
    text-align: center;
    font-size: 24px;
    color: #FFF;
  }
  p {
    display: block;
    text-align: center;
    margin: $mar 0px;
    font-weight: bold;
  }
  .btn {
    text-align: center;
    margin:0 auto;
  }
}
/* COURSE ENQUIRY */
#EnquireModal .modal-content {
  border-radius: 0 !important;
  text-align: center;
}
#EnquireModal .modal-header h4 {
  color: $blue;
}
#EnquireModal label {
  font-weight: bold;
}
#EnquireModal .modal-footer {
  text-align: center;
}
